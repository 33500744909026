import React from 'react'
import ClientPage from './Client/Client'
import { NextUIProvider } from '@nextui-org/react'

export default function RouterApp() {
  return (
    <NextUIProvider>
      <ClientPage />
    </NextUIProvider>
  )
}
