const tg = window.Telegram.WebApp
const tgWebProxy = window.TelegramWebviewProxy

export function hexToRGBA(hex, opacity) {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export function parserDateToString(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed in JavaScript
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export function sendDataUpdate(data, truck_id) {
  const myHeaders = new Headers()
  myHeaders.append('accept', 'application/json')
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('telegramid', tg.initDataUnsafe?.user?.id)

  const raw = JSON.stringify({
    truck_id: truck_id,
    ...data
  })

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  }

  return fetch(
    `${process.env.REACT_APP_HOST}/api/webapp/trucks/update`,
    requestOptions
  )
}

export function useTelegram() {
  const onClose = () => {
    tg.close()
  }

  const onToggleButton = () => {
    if (tg.MainButton.isVisible) {
      tg.MainButton.hide()
    } else {
      tg.MainButton.show()
    }
  }

  const showMainButton = () => {
    tg.MainButton.enable()
    tg.MainButton.show()
  }

  const expand = () => {
    if (!tg.isExpanded) {
      tg.expand()
    }
  }

  return {
    tg,
    tgWebProxy,
    expand,
    user: tg.initDataUnsafe?.user,
    queryId: tg.initDataUnsafe?.query_id,
    initData: tg.initDataUnsafe,
    onClose,
    onToggleButton,
    showMainButton
  }
}
